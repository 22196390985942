require('babel-core/register')
require('babel-polyfill')
const token = '921557ac4f97c9f4644c1f34541d0b'
const getData = async () => {
    try {
        const res = await fetch('https://graphql.datocms.com/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                query: `{
                    allNieruchomoscWynajems(filter: {best: {eq: "true"}}) {
                      lokalizacja
                      cena
                      pietro
                      krotkiOpis
                      pokoje
                      powierzchnia
                      identyfikator
                      zdjecie {
                        url
                      }
                    }
                    allNieruchomoscSprzedazs(filter: {best: {eq: "true"}}) {
                      lokalizacja
                      cena
                      pietro
                      krotkiOpis
                      pokoje
                      powierzchnia
                      identyfikator
                      zdjecie {
                        url
                      }
                    }
                  }`,
            }),
        })

        return res.json()
    } catch (err) {
        console.log(err.message)
    }
}

getData().then(res => showData(res))
const bestOffers = document.querySelector('.best-offers')
let html = ''

const showData = res => {
    console.log(res.data)
    const allProperties = res.data.allNieruchomoscSprzedazs.concat(
        res.data.allNieruchomoscWynajems
    )

    allProperties.forEach(property => {
        html += `
        <a href="properties/${property.identyfikator}.html">
        <div class="best-offers__card card show">
            <div class="hide">
                 <img
                class="card__img"
                src="${property.zdjecie.url}"
                alt="mieszkanie"
                />
            </div>
           
            <div class="card-wrapper">
                <h4 class="card__title">${property.lokalizacja}</h4>
                <p class="card__description">
                ${property.krotkiOpis}
                </p>
                <div class="card__data data">
                    <div class="data__item">
                        Pokoje: <span class="data__item--bold">${property.pokoje}</span>
                    </div>
                    <div class="data__item">
                        Powierzchnia:
                        <span class="data__item--bold">${property.powierzchnia}</span>
                    </div>
                    <div class="data__item">
                        Piętro: <span class="data__item--bold">${property.pietro}</span>
                    </div>
                </div>
                <div class="card__price">${property.cena}</div>
            </div>
        </div>
    </a>
        `
    })

    bestOffers.insertAdjacentHTML('beforeend', html)
}
